import request from '@/utils/request'


// 获取图表接口
export function getchartsList(query) {
  return request({
    url: '/user/visaApply/statistics',
    method: 'get',
    params: query
  })
}


