<template>
  <!--  <page-header-wrapper>-->
  <!--    <template v-slot:content>-->
  <!--      <div class="page-header-content">-->
  <div id="container">
    <div class="page_top">
      <div class="top_left">
        <!-- <div class="logo">
                <img src="images/logo.jpg"/>
              </div> -->
        <!-- <h2 class="wms_name"><span>保税区A仓</span><em>单仓</em></h2> -->
        <!-- <div class="depot"> -->
        <!-- <input type="text" placeholder="选择仓库..." readonly="readonly"/> -->
        <!-- <div class="depo_down">
                  <ul>
                    <li>A仓库</li>
                    <li>B仓库</li>
                    <li>C仓库</li>
                  </ul>
                </div> -->
        <!-- </div> -->
      </div>
      <div class="top_time">
        <!--<input type="text" class="time_star" id="time_star" :value="formattedDate1()" readonly="readonly" />-->
        <a-date-picker
          class="time_star"
          v-model="date1"
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          placeholder="开始时间"
          @ok="handleStartOpenChange"
        />
        <span>至 </span>
        <!-- <input type="text" class="time_end" id="time_end" :value="formattedDate()" readonly="readonly" /> -->
        <a-date-picker
          v-model="date2"
          show-time
          format="YYYY-MM-DD HH:mm:ss"
          valueFormat="YYYY-MM-DD HH:mm:ss"
          placeholder="结束时间"
          class="time_end"
          @ok="handleEndOpenChange"
        />
      </div>
    </div>
    <!--顶部-->
    <div class="order_list">
      <div class="order">
        <div class="order_name">总用户量</div>
        <h2 class="order_num">{{ totalUserNum }}</h2>
      </div>
      <div class="order">
        <div class="order_name">新用户</div>
        <h2 class="order_num">{{ registerUserNum }}</h2>
      </div>
      <div class="order">
        <div class="order_name">递签中数量</div>
        <h2 class="order_num">{{ submitApplyNum }}</h2>
      </div>
      <div class="order">
        <div class="order_name">递签待反馈数量</div>
        <h2 class="order_num">{{ feedbackApplyNum }}</h2>
      </div>
      <div class="order">
        <div class="order_name">递签成功数量</div>
        <h2 class="order_num">{{ successApplyNum }}</h2>
      </div>
    </div>
    <!--订单量-->
    <div class="main">
      <div class="main_left">
        <div class="home_today">
          <!-- <div class="home_tit">今天作业趋势图</div> -->
          <div class="home_list">
            <div class="home_order">
              <div class="home_name">总申请数量</div>
              <div class="home_num new_num">{{ totalApplyNum }}</div>
            </div>
            <div class="home_order">
              <div class="home_name">递签中数量</div>
              <div class="home_num jian_num">{{ submitApplyNum }}</div>
            </div>
            <div class="home_order">
              <div class="home_name">递签待反馈数量</div>
              <div class="home_num zhi_num">{{ feedbackApplyNum }}</div>
            </div>
            <div class="home_order">
              <div class="home_name">递签成功数量</div>
              <div class="home_num chu_num">{{ successApplyNum }}</div>
            </div>
          </div>
          <div id="home_today"></div>
          <!--今日作业趋势图-->
        </div>
        <div class="ship_order" id="ship_order"></div>
        <!--货主订单执行情况-->
      </div>
      <div class="main_midd">
        <div class="imple" id="imple"></div>
        <!--订单执行情况-->
        <div class="order_wait">
          <div class="commis">
            <div class="comm_tit">签证申请统计</div>
            <div class="comm_list">
              <div class="comm_cont">
                <span class="com_name">总申请数量</span><span class="com_num">{{ totalApplyNum }}</span>
              </div>
            </div>
            <div class="comm_list">
              <div class="comm_cont">
                <span class="com_name">新用户</span><span class="com_num">{{ registerUserNum }}</span>
              </div>
            </div>
            <div class="comm_list">
              <div class="comm_cont">
                <span class="com_name">递签中数量</span><span class="com_num">{{ submitApplyNum }}</span>
              </div>
            </div>
            <div class="comm_list">
              <div class="comm_cont">
                <span class="com_name">递签待反馈数量</span><span class="com_num">{{ feedbackApplyNum }}</span>
              </div>
            </div>
            <div class="comm_list">
              <div class="comm_cont">
                <span class="com_name">递签成功数量</span><span class="com_num">{{ successApplyNum }}</span>
              </div>
            </div>
            <!-- <div class="comm_list">
              <div class="comm_cont"><span class="com_name">发货等待</span><span class="com_num">0</span></div>
            </div>
            <div class="comm_list">
              <div class="comm_cont"><span class="com_name">异常等待</span><span class="com_num">0</span></div>
            </div> -->
          </div>
          <!--订单代办事项-->
          <div class="abort">
            <div class="abot_tit">推广成本统计</div>
            <div class="abort_box">
              <div class="abort_list">
                <div class="abort_use">
                  <div class="abort_cont" v-for="(item, index) in referrerList" :key="index">
                    <div class="abort_name">{{ item.referrerName }}</div>
                    <div class="abort_num">{{ item.avgAmount }}</div>
                    <div class="abort_num">{{ item.referCount }}</div>
                  </div>
                  <!-- <div class="abort_cont">
                    <div class="abort_name">货主2</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主3</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主4</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主5</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主6</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主4</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主5</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主6</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主4</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主5</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主6</div>
                    <div class="abort_num">90%</div>
                  </div>
                  <div class="abort_cont">
                    <div class="abort_name">货主7</div>
                    <div class="abort_num">90%</div>
                  </div> -->
                </div>
              </div>
            </div>
          </div>
          <!--截止时间出库率-->
        </div>
      </div>
    </div>
    <div class="main_bott">
      <div class="title">
        <span>活跃用户</span>
        <a-radio-group
          default-value="3"
          button-style="solid"
          class="radioGroup"
          size="small"
          v-model="userType"
          @change="change"
        >
<!--          <a-radio-button value="3"> 年 </a-radio-button>-->
          <a-radio-button value="1"> 日 </a-radio-button>
          <a-radio-button value="2"> 月 </a-radio-button>

        </a-radio-group>
      </div>
      <div id="main_bott" class="box"></div>
    </div>
  </div>
  <!--   </div>-->
  <!-- </template> -->

  <!--  </page-header-wrapper>-->
</template>

<script>
import * as echarts from 'echarts'
import { PageHeaderWrapper } from '@/components/ProLayout'
import { getchartsList } from '@/api/dataCharts/charts.js'
import moment from 'moment'
export default {
  name: 'Index',
  components: {
    PageHeaderWrapper,
  },
  data() {
    return {
      totalApplyNum: 0,
      totalUserNum: 0, //总用户量
      registerUserNum: 0, // 新用户数量
      submitApplyNum: 0, //递签中用户数量
      successApplyNum: 0, //递签成功用户数量
      feedbackApplyNum: 0, //递签待反馈用户数量
      dataList: [], // x轴时间
      feedbackApplyNum1: [], //待反馈申请数量
      submitApplyNum1: [], //递签中申请数量
      successApplyNum1: [], // 递签成功申请数量
      totalApplyNum1: [], //总申请数量
      currentDate: new Date(),
      date1: this.formattedDate1(),
      date2: this.formattedDate(),
      userType: '1',
      number: [], // 平均成本
      investedAmount: [], // 投放金额
      referCount: [], //  推广人数,
      name: [], //推广人
      referrerList: [],
      timeList:[],
      userList:[]
    }
  },
  computed: {},
  created() {
    this.getData()
  },
  mounted() {
    this.initChart()
    this.getData()
  },
  methods: {
    initChart() {
      const chartDom = document.getElementById('home_today')
      const chartDom1 = document.getElementById('ship_order')
      const chartDom2 = document.getElementById('imple')
      const chartDom3 = document.getElementById('main_bott')
      const myChart = echarts.init(chartDom)
      const myChart1 = echarts.init(chartDom1)
      const myChart2 = echarts.init(chartDom2)
      const myChart3 = echarts.init(chartDom3)

      var option1 = {
        backgroundColor: '#1b1e25',
        title: {
          text: '',
        },

        tooltip: {
          enterable: true,
          trigger: 'axis',
        },
        grid: {
          left: '5%',
          right: '3%',
          top: '10%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#3e4148',
                width: 1, //这里是为了突出显示加上的
              },
            },
            type: 'category',
            boundaryGap: false,
            data: this.dataList,
            axisLabel: {
              color: '#ccc',
              rotate: 45, // 设置文字倾斜的角度，45 度为例
            },
            // [
            //   '2222',
            //   '1',
            //   '2',
            //   '3',
            //   '4',
            //   '5',
            //   '6',
            //   '7',
            //   '8',
            //   '9',
            //   '10',
            //   '11',
            //   '12',
            //   '13',
            //   '14',
            //   '15',
            //   '16',
            //   '17',
            //   '18',
            //   '19',
            //   '20',
            //   '21',
            //   '22',
            //   '23',
            // ],
          },
        ],
        yAxis: [
          {
            splitLine: {
              lineStyle: {
                color: '#21242b',
              },
            },
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#43484e',
                width: 0, //这里是为了突出显示加上的
              },
            },
          },
        ],
        series: [
          {
            name: '待反馈申请数量',
            type: 'line',
            symbol: 'none',
            data: this.feedbackApplyNum1,
            // [50, 132, 40, 1500, 2000, 800, 210, 100],
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: '#533d86',
                },
              },
            },
            areaStyle: { normal: { color: ['rgba(255,255,255,0.1)'] } },
          },
          {
            name: '递签中申请数量',
            type: 'line',
            symbol: 'none',
            data: this.submitApplyNum1,
            // [2, 8, 500, 2000, 200, 100, 50, 50],
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: '#60ba9e',
                },
              },
            },
            areaStyle: { normal: { color: ['rgba(255,255,255,0.1)'] } },
          },
          {
            name: '递签成功申请数量',
            type: 'line',
            symbol: 'none',
            data: this.successApplyNum1,
            // [150, 500, 530, 1500, 1000, 330, 410, 60],
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: '#476d9e',
                },
              },
            },
            areaStyle: { normal: { color: ['rgba(255,255,255,0.1)'] } },
          },
          {
            name: '总申请数量',
            type: 'line',
            symbol: 'none',
            data: this.totalApplyNum1,
            // [800, 400, 1500, 1200, 800, 700, 600, 200],
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: '#b1526a',
                },
              },
            },
            areaStyle: { normal: { color: ['rgba(255,255,255,0.1)'] } },
          },
        ],
      }
      var option2 = {
        //    avgAmount     平均成本	number
        // investedAmount	投放金额	number
        // referCount	推广人数	integer
        // referrerMobile	推广人手机号
        backgroundColor: '#1b1e25',
        title: {
          text: '推广统计',
          textStyle: {
            //设置主标题风格
            color: '#ffffff', //设置主标题字体颜色
            fontSize: '14px',
          },
          subtext: '',
          subtextStyle: {
            color: '#ffffff', // 副标题文字颜色
          },
        },
        color: ['#ed4b97', '#26a0c9', '#44cb90', '#d2bd7a'],
        tooltip: {},
        legend: {
          data: ['平均成本', '投放金额', '推广人数'],
          textStyle: {
            color: '#ffffff',
          },
          right: 10,
          itemWidth: 10,
          itemHeight: 10,
        },

        calculable: true,
        grid: {
          left: '3%',
          right: '3%',
          bottom: '20',
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            axisTick: { show: false },
            data: this.name,
            // ['货主1', '货主2', '货主3', '货主4', '货主5', '货主6', '货主7'],
            axisLabel: {
              interval: 0,
              // color: '#ccc',
              rotate: 45, // 设置文字倾斜的角度，45 度为例
            },
            axisLine: {
              lineStyle: {
                color: '#ffffff',
                width: 1, //这里是为了突出显示加上的
              },
            },
          },
        ],
        dataZoom: [
          {
            type: 'slider',
            show: true, //flase直接隐藏图形
            xAxisIndex: [0],
            left: '9%', //滚动条靠左侧的百分比
            bottom: -5,
            height: 15,
            start: 0, //滚动条的起始位置
            end: 50,
          },
        ],
        yAxis: [
          {
            splitLine: {
              lineStyle: {
                color: '#21242b',
              },
            },
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#43484e',
                width: 0, //这里是为了突出显示加上的
              },
            },
          },
        ],
        series: [
          {
            name: '平均成本',
            type: 'bar',
            barGap: 0.2,
            data: this.number,
            // [320, 332, 310, 200, 222, 40, 300],
          },
          {
            name: '投放金额',
            type: 'bar',
            data: this.investedAmount,
            // [130, 182, 30, 50, 122, 20, 30],
          },
          {
            // 递签成功申请数量
            name: '推广人数',
            type: 'bar',
            data: this.referCount,
            // [320, 82, 220, 250, 322, 50, 10],
          },
          // {
          //   name: '推广人手机号',
          //   type: 'bar',
          //   data: this.totalApplyNum1,
          //   // [100, 182, 120, 100, 22, 100, 30],
          // },
        ],
      }
      var option3 = {
        grid: {
          left: '3%',
          right: '3%',
          top: '10%',
          bottom: '2%',
          containLabel: true,
        },
        title: {
          text: '签证申请情况',
          textStyle: {
            //设置主标题风格
            color: '#90939a', //设置主标题字体颜色
            fontSize: '14px',
          },
        },
        backgroundColor: '#1b1e25',
        tooltip: {
          trigger: 'item',
          formatter: ' {b}:{d}%',
        },
        legend: {
          orient: 'vertical',
          right: 10,
          y: 'center',
          textStyle: {
            color: '#7c8081',
          },
          itemWidth: 10,
          itemHeight: 10,
          data: ['待反馈申请数量', '递签中申请数量', '递签成功申请数量', '总申请数量'],
          formatter: function (params) {
            for (var i = 0; i < option3.series[0].data.length; i++) {
              if (option3.series[0].data[i].name == params) {
                return params + ':' + option3.series[0].data[i].value
              }
            }
          },
        },
        series: [
          {
            name: '递签申请情况',
            type: 'pie',
            radius: ['50%', '70%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'center',
              },
              emphasis: {
                show: true,
                textStyle: {
                  fontSize: '20',
                  fontWeight: 'bold',
                },
              },
            },

            labelLine: {
              normal: {
                show: false,
              },
            },
            data: [
              { value: this.feedbackApplyNum, name: '待反馈申请数量' },
              { value: this.submitApplyNum, name: '递签中申请数量' },
              { value: this.successApplyNum, name: '递签成功申请数量' },
              { value: this.totalUserNum, name: '总申请数量' },
            ],
            itemStyle: {
              emphasis: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
              normal: {
                color: function (params) {
                  //自定义颜色
                  var colorList = ['#74529e', '#da8f3c', '#447cb7', '#be4868', '#4dc892', '#efbb43']
                  return colorList[params.dataIndex]
                },
              },
            },
          },
        ],
      }

      var option4 = {
        backgroundColor: '#1b1e25',
        title: {
          text: '',
        },

        tooltip: {
          enterable: true,
          trigger: 'axis',
        },
        grid: {
          left: '5%',
          right: '3%',
          top: '10%',
          bottom: '5%',
          containLabel: true,
        },
        xAxis: [
          {
            axisLine: {
              lineStyle: {
                color: '#3e4148',
                width: 1, //这里是为了突出显示加上的
              },
            },
            type: 'category',
            boundaryGap: false,
            data: this.timeList,
            axisLabel: {
              color: '#ccc',
              rotate: 45, // 设置文字倾斜的角度，45 度为例
            },
            // [
            //   '2222',
            //   '1',
            //   '2',
            //   '3',
            //   '4',
            //   '5',
            //   '6',
            //   '7',
            //   '8',
            //   '9',
            //   '10',
            //   '11',
            //   '12',
            //   '13',
            //   '14',
            //   '15',
            //   '16',
            //   '17',
            //   '18',
            //   '19',
            //   '20',
            //   '21',
            //   '22',
            //   '23',
            // ],
          },
        ],
        yAxis: [
          {
            splitLine: {
              lineStyle: {
                color: '#21242b',
              },
            },
            type: 'value',
            axisLine: {
              lineStyle: {
                color: '#43484e',
                width: 0, //这里是为了突出显示加上的
              },
            },
          },
        ],
        series: [
          {
            name: '创建申请用户数',
            type: 'line',
            symbol: 'none',
            data: this.userList,
            // [50, 132, 40, 1500, 2000, 800, 210, 100],
            smooth: true,
            itemStyle: {
              normal: {
                lineStyle: {
                  color: '#533d86',
                },
              },
            },
            areaStyle: { normal: { color: ['rgba(255,255,255,0.1)'] } },
          },
         
        ],
      }

      option1 && myChart.setOption(option1)
      option2 && myChart1.setOption(option2)
      option3 && myChart2.setOption(option3)
      option4 && myChart3.setOption(option4)
    },
    formatNum(str) {
      var newStr = ''
      var count = 0
      if (str.indexOf('.') == -1) {
        for (var i = str.length - 1; i >= 0; i--) {
          if (count % 3 == 0 && count != 0) {
            newStr = str.charAt(i) + ',' + newStr
          } else {
            newStr = str.charAt(i) + newStr
          }
          count++
        }
        str = newStr
        return str
      }
    },
    getData() {
      getchartsList({
        // createBeginTime: '2024-07-01 00:00:00',
        // createEndTime: '2024-08-01 23:59:59',
        createBeginTime: this.date1,
        createEndTime: this.date2,
        userType: this.userType,
      }).then((res) => {
        console.log('数据', res)
        this.totalUserNum = res.data.totalUserNum //总用户量
        this.registerUserNum = res.data.registerUserNum // 新用户数量
        this.submitApplyNum = res.data.submitApplyNum //递签中用户数量
        this.successApplyNum = res.data.successApplyNum //递签成功用户数量
        this.feedbackApplyNum = res.data.feedbackApplyNum //递签待反馈用户数量
        this.totalApplyNum = res.data.totalApplyNum
        this.referrerList = res.data.referrerList
        let data = res.data.dayApplyList.map((item) => {
          return item.date
        })
        let feedbackApplyNum1 = res.data.dayApplyList.map((item) => {
          return item.feedbackApplyNum
        })
        let submitApplyNum1 = res.data.dayApplyList.map((item) => {
          return item.submitApplyNum
        })
        let successApplyNum1 = res.data.dayApplyList.map((item) => {
          return item.successApplyNum
        })
        let totalApplyNum1 = res.data.dayApplyList.map((item) => {
          return item.totalApplyNum
        })

        let number = res.data.referrerList.map((item) => {
          return item.avgAmount
        })
        let investedAmount = res.data.referrerList.map((item) => {
          return item.investedAmount
        })
        let referCount = res.data.referrerList.map((item) => {
          return item.referCount
        })

        let name = res.data.referrerList.map((item) => {
          return item.referrerName
        })

        let time = res.data.applyUserList.map(item =>{
             return item.date
        })
        let userList = res.data.applyUserList.map(item =>{
            return item.count
        })
        this.timeList = time
        this.userList = userList
        this.dataList = data
        this.feedbackApplyNum1 = feedbackApplyNum1
        this.submitApplyNum1 = submitApplyNum1
        this.successApplyNum1 = successApplyNum1
        this.totalApplyNum1 = totalApplyNum1
        this.number = number
        this.investedAmount = investedAmount
        this.referCount = referCount
        console.log('推广人数',this.referCount)
        this.name = name
        this.initChart()

        // console.log(feedbackApplyNum)
      })
    },
    formattedDate() {
      return moment(this.currentDate).format('YYYY-MM-DD HH:mm:ss')
    },
    formattedDate1() {
      // 获取当前日期
      var currentDate = moment()

      // 往前推一个月
      var previousMonthDate = currentDate.subtract(1, 'months').format('YYYY-MM-DD HH:mm:ss')
      return previousMonthDate
    },
    handleStartOpenChange(e) {
      // console.log(this.date1);
      this.initChart()
      this.getData()
    },
    handleEndOpenChange(e) {
      //  console.log(this.date2);
      this.initChart()
      this.getData()
    },
    // 年月日 change
    change(e) {
      // console.log(e.target.value)
      this.userType = e.target.value

      this.initChart()
      this.getData()

    },
  },
}
</script>

<style lang="less" scoped>
@import './index.less';
@import './reset.less';
@import './style.less';
</style>
